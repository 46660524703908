export const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_URL = API_URL + '/reporting/login'
export const OTP_URL = API_URL + '/reporting/otp/validate'
export const NEW_POST_OTP = API_URL + '/reporting/otp/resend'
export const LABNAME_URL = API_URL + '/reporting/usvi/labs'
// Sort by
export const SORTBY = API_URL + '/reporting/sort/filter/results'


// Search record
export const SEARCH_RECORD = API_URL + '/reporting/get/test/records'
// Refresh Token
export const REFRESH_TOKEN = API_URL + '/reporting/refresh/token?refreshToken='
